//
//
//
//
//

import { mapGetters } from "vuex";
export default {
  components: {
    MessaggiDesktop: () =>
      import("@/components/messaggi/desktop/messaggi_desktop_main.vue"),
    MessaggiMobile: () =>
      import("@/components/messaggi/mobile/messaggi_main_mobile.vue"),
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      lista: "get_lista_messaggi",
    }),
  },
};
